import React from 'react'
import qassured from '../../../Q-Assured.png'


const BadgeGreen = () => {
  return (
    <div>
    <img src={qassured} alt='f-assured' className='h-20 '/>
    </div>
  )
}

export default BadgeGreen
